import React, { Component } from "react";
import PropTypes from "prop-types";
import { firestoreConnect } from "react-redux-firebase";

// import styled from "styled-components";
import PatternedHeader from "../elements/PatternedHeader";
import FormBox from "../elements/FormBox";
import FullWidth from "../elements/FullWidth";
import StyledHeadingOne from "../elements/StyledHeadingOne";

class SignUp extends Component {
  state = {
    bizDesc: "",
    bizName: "",
    category: "",
    email: "",
    extURL: "",
    firstName: "",
    imgURL: "",
    lastName: "",
    password: "",
    phone: ""
  };

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  onCreate = e => {
    e.preventDefault();
    const { firestore, firebase, history } = this.props;

    //Get items from state to use to set values in the create method below.

    const {
      bizDesc,
      bizName,
      category,
      email,
      extURL,
      firstName,
      imgURL,
      lastName,
      password,
      phone
    } = this.state;

    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(user => {
        //Get the unique UID generated by firebase and use it to set the doc name.
        let user_id = user.user.uid;
        firestore
          .collection("users")
          .doc(user_id)
          .set({
            //These values will come from the state unless explicitly specified below.
            user_id: user_id,
            featured: true,
            bizDesc,
            bizName,
            category,
            email,
            extURL,
            firstName,
            imgURL:
              "https://firebasestorage.googleapis.com/v0/b/moco-project-53ad1.appspot.com/o/images%2Ffavicon.ico?alt=media&token=1da8464f-7aac-4b2f-b3ec-ababdcc8985b",
            lastName,
            password,
            phone
          })
          .then(() => history.replace(`/edit/${user_id}`));
      })
      .catch(error => alert(error));
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <FullWidth>
        <PatternedHeader />
        <FormBox>
          <StyledHeadingOne
            color="var(--dark-teal)"
            content="Create Your Profile"
          />
          <form onSubmit={this.onCreate}>
            <div className="form-group">
              <label htmlFor="firstName">First Name*</label>
              <input
                type="text"
                className="form-control"
                name="firstName"
                required
                value={this.state.firstName}
                onChange={this.onChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="lastName">Last Name*</label>
              <input
                type="text"
                className="form-control"
                name="lastName"
                required
                value={this.state.lastName}
                onChange={this.onChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="bizName">Business Name*</label>
              <input
                type="text"
                className="form-control"
                name="bizName"
                required
                value={this.state.bizName}
                onChange={this.onChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="category">Business Category*</label>
              <select
                type="text"
                className="form-control"
                name="category"
                required
                value={this.state.category}
                onChange={this.onChange}
              >
                <option value="" selected disabled hidden>
                  Select a category
                </option>
                <option value="Art & Photography">Art & Photography</option>
                <option value="Clothing & Accessories">
                  Clothing & Accessories
                </option>
                <option value="Entertainment">Entertainment</option>
                <option value="Hair & Beauty">Hair & Beauty</option>
                <option value="Health & Fitness">Health & Fitness</option>
                <option value="Kids & Baby">Kids & Baby</option>
                <option value="Household">Household</option>
                <option value="Pets">Pets</option>
                <option value="Professional Services">
                  Professional Services
                </option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="bizDesc">
                Business Description* (1-2 sentences about what you offer!)
              </label>
              <textarea
                type="text"
                className="form-control"
                name="bizDesc"
                required
                value={this.state.bizDesc}
                onChange={this.onChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="extURL">Website URL</label>
              <input
                type="url"
                className="form-control"
                name="extURL"
                value={this.state.extURL}
                onChange={this.onChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="phone">Phone (xxx-xxx-xxxx)</label>
              <input
                type="tel"
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                className="form-control"
                name="phone"
                value={this.state.phone}
                onChange={this.onChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">Email*</label>
              <input
                type="email"
                className="form-control"
                name="email"
                required
                value={this.state.email}
                onChange={this.onChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="password">Password* (Minimum 6 characters)</label>
              <input
                type="password"
                className="form-control"
                name="password"
                required
                value={this.state.password}
                onChange={this.onChange}
              />
            </div>
            <p>
              We are so excited you're joining us! You will be able to add an
              image to your profile and review your info on the next page.
            </p>
            <input
              type="submit"
              value="CREATE"
              className="btn btn-primary btn-block"
            />
          </form>
        </FormBox>
      </FullWidth>
    );
  }
}

SignUp.propTypes = {
  firebase: PropTypes.object.isRequired,
  firestore: PropTypes.object.isRequired
};

export default firestoreConnect()(SignUp);
